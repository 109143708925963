<div class="footer" nz-row nzType="flex">
  <!--Informations links-->
  <div class="footer__informations" nz-col [nzXs]="24" [nzLg]="8">
    <span>
      <a [routerLink]="['/regulations', 'informations']" target="_blank">
        {{ "FOOTER.INFORMATIONS" | translate }}
      </a>
      <a [routerLink]="['/regulations', 'terms']" target="_blank">
        {{ "FOOTER.TERMS" | translate }}
      </a>
      <a [routerLink]="['/regulations', 'privacy_politics']" target="_blank">
        {{ "FOOTER.PRIVACY_POLICIES" | translate }}
      </a>
      <!--
      <a [href]="faultComunicationLink" target="_blank">
        {{"FOOTER.FAULT_COMMUNICATION" | translate}}
      </a>
      -->
      <a [routerLink]="['/acessibilidade']" target="_blank">
        {{"FOOTER.ACCESSIBILITY_DECLARATION" | translate}}
      </a>

    </span>
  </div>

  <!--Copyright-->
  <div class="footer__copyright" nz-col [nzXs]="0" [nzLg]="8">
    <span>Portal das Entidades © {{ current_date | date: "yyyy" }} | v{{ version }}</span>
  </div>

  <div class="footer__logo" nz-col [nzXs]="0" [nzLg]="8">
    <img *ngIf="isCRMRoute" src="'./assets/imgs/compete-crm.svg'" />
    <img *ngIf="isJobPortalRoute" src="./assets/imgs/prr.svg" />
    <img style="width: 100%;" *ngIf="isInclusiveSchoolRoute" src="./assets/imgs/footer_inclusive_school.svg" />
  </div>
</div>