<nz-spin [nzSpinning]="routerLoading" [nzSize]="'large'" [nzTip]="'MISC.LOADING_PAGE' | translate"
  style="min-height: 200px">
  <router-outlet></router-outlet>
</nz-spin>
<ng-template #cookieMsg let-notification>
  <div class="ant-notification-notice-content">
    <div>
      <div class="ant-notification-notice-message">Cookies</div>
      <div class="ant-notification-notice-description">
        {{ "COOKIE_CONSENT.MESSSAGE" | translate }}
      </div>
      <span class="ant-notification-notice-btn">
        <button nz-button nzType="primary" nzSize="small" (click)="cookieConsent(notification)">
          <span>{{ "COOKIE_CONSENT.BUTTON" | translate }}</span>
        </button>
      </span>
    </div>
  </div>
</ng-template>