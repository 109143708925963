import { first } from "rxjs/operators";
import { MessageType } from "./../../core/services/ui.service";
import { AuthService } from "../services/auth.service";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  CanActivateChild,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { UiService } from "@fi-sas/webpage/core/services/ui.service";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PrivateGuard implements CanActivate, CanActivateChild {
  objKey: string;

  constructor(
    private router: Router,
    private uiService: UiService,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.tokenVerification(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return of(!!this.authService.getToken());
  }

  tokenVerification(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return Observable.create((observable) => {
      if (this.authService.getIsGuest()) {
        this.router.navigate(["auth", "login"], {
          queryParams: { returnUrl: state.url },
        });
        observable.next(false);

        return;
      } else {
        if (this.authService.getIsLogged()) {
          // IF TOKEN IS VALID
          if (route.data.hasOwnProperty("scope")) {
            let hasPermission = true;

            if (Array.isArray(route.data["scope"])) {
              route.data["scope"].map((scope) => {
                if (!this.authService.hasPermission(scope)) {
                  hasPermission = false;
                }
              });
            } else {
              // IF HAS SCOPE PROPRIETY ON ROUTING
              hasPermission = this.authService.hasPermission(
                route.data["scope"]
              );
            }

            if (hasPermission) {
              // IF USER HAS THE SCOPE OF THE ROUTING
              observable.next(true);
            } else {
              if (route.data["fallbackUrl"]) {
                this.router.navigate([route.data["fallbackUrl"]]);
                observable.next(false);
                return;
              }
              // IF USER DONT HAVE THE SCOPE OF ROUTING
              this.router.navigate(["unauthorized"]);
              observable.next(false);
            }
          } else if (route.data.hasOwnProperty("profile")) {
            if (
              this.authService.getUser().profile.name.toLowerCase() ===
              route.data["profile"].toLowerCase()
            ) {
              observable.next(true);
            } else {
              this.router.navigate(["unauthorized"]);
              observable.next(false);
            }
          } else {
            // IF IS ONLY CHECK OF VALID TOKEN
            observable.next(true);
          }
        } else {
          // IF TOKEN IS NOT VALID
          this.router.navigate(["auth", "login"], {
            queryParams: { returnUrl: state.url },
          });
          observable.next(false);
          return;
        }
      }
      observable.complete();
    });
  }
}
