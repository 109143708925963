<div nz-row nzAlign="middle" class="header-web hidden-xs">

  <!--SASOCIAL-->
  <div nz-col nzSpan="14">
    <div class="title" (click)="goDashboard();">
      <img [src]="'assets/logos/white/IPVC.svg?v1'">
    </div>

    <app-layout-breadcrumb></app-layout-breadcrumb>

  </div>

  <!--&lt;!&ndash;Breadcrumb&ndash;&gt;-->
  <!--<div nz-col nzSpan="8">-->
  <!--<app-layout-breadcrumb></app-layout-breadcrumb>-->
  <!--</div>-->

  <!--User Header-->
  <div nz-col nzSpan="10" class="user">
    <app-layout-header-user></app-layout-header-user>
  </div>

</div>

<div nz-row nzType="flex" nzAlign="middle" nzJustify="space-around" class="header-mobile hidden-md">


  <div nz-col [nzSpan]="4" class="fold-button hidden-tablet" (click)="openSideMenu()">
    <i nz-icon nzType="icons:icons-menu" nzTheme="outline"></i>
  </div>


  <!--SASOCIAL-->
  <!--- <div nz-col [nzXs]="0" [nzSm]="0" [nzMd]="14" class="title-mobile">
    <img [src]="'assets/logos/black/' + institute + '.svg'" >
  </div>-->

  <!--User Header-->
  <div nz-col [nzXs]="20" [nzSm]="20" [nzMd]="24" [nzLg]="9" [nzXl]="9" [nzXXl]="9" class="user-mobile">
    <app-layout-header-user></app-layout-header-user>
  </div>

  <!--MOBILE MENU-->
  <div class="hidden-md">
    <app-layout-mobile-menu></app-layout-mobile-menu>
  </div>

</div>