<div class="current-account">
    <button nz-button nzType="link" nz-popover [nzPopoverTitle]="'CURRENT_ACCOUNT.ACCOUNT' | translate"
        [(nzPopoverVisible)]="visibleCurrentAccounts" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate"
        class="current-account__button">
        <i nz-icon nzType="icons:walltet_v3" style="font-size:22px" nzTheme="outline" class="header-icon"></i>
    </button>
</div>
<ng-template #contentTemplate>
    <div *ngIf="!loading">
        <nz-list [nzDataSource]="$currentAccounts | async" [nzRenderItem]="defaultItem" [nzItemLayout]="'horizontal'"
            style="font-size: 12px; height: 300px; overflow-y: scroll; width: 250px;">
            <ng-template #defaultItem let-current>
                <div nz-row style="padding-top: 8px;">
                    <div nz-col [nzXs]="24" [nzMd]="4" [nzLg]="3" class="icon-top">
                        <i nz-icon nzType="icons:walltet_v3" id="icon-curentac" style="font-size:22px" nzTheme="outline"
                            class="header-icon"></i>
                    </div>
                    <div nz-col [nzXs]="24" [nzMd]="20" [nzLg]="20" style="padding-top: 2px;">
                        <div nz-row>
                            <label class="current-account__title">{{current.account_name}}</label>
                        </div>
                        <div nz-row>
                            <div nz-col [nzXs]="15" [nzMd]="15" [nzLg]="15">
                                <label class="current-account__text"> {{ 'CURRENT_ACCOUNT.CURRENT_ACCOUNT' | translate
                                    }}</label>
                            </div>
                            <div nz-col [nzXs]="9" [nzMd]="9" [nzLg]="9" class="current-account__right">
                                <span class="current-account__account-value-corrent">{{current.current_balance |
                                    number:'1.2-2'}} €</span>
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzXs]="15" [nzMd]="15" [nzLg]="15">
                                <label class="current-account__text"> {{ 'CURRENT_ACCOUNT.OUTSTANDING_PAYMENTS' |
                                    translate }}</label>
                            </div>
                            <div nz-col [nzXs]="9" [nzMd]="9" [nzLg]="9" class="current-account__right">
                                <span
                                    [ngClass]="{'current-account__account-value-pending': current?.in_debt != 0, 
                                'current-account__account-value-pending-normal': current?.in_debt == 0}">{{current.in_debt
                                    | number:'1.2-2'}} €</span>
                            </div>
                        </div>
                    </div>

                </div>
            </ng-template>
        </nz-list>
    </div>
    <div *ngIf="loading" nz-row class="padding-loading" style="width: 250px;">
        <nz-spin [nzSpinning]="loading">

        </nz-spin>
    </div>
    <div nz-row class="border-buttons">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="11" [nzLg]="11" [nzXl]="11" [nzXXl]="11" class="button-more">

        </div>
        <div nz-col [nzXs]="0" [nzSm]="0" [nzMd]="2" [nzLg]="2" [nzXl]="2" [nzXXl]="2">

        </div>
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="11" [nzLg]="11" [nzXl]="11" [nzXXl]="11" class="space-button">
            <button nz-button class="ant-btn ant-btn-default ant-btn-round button"
                (click)="refresh();">{{'REPORTS.REFRESH' | translate}}</button>
        </div>
    </div>
</ng-template>