import { Injectable } from '@angular/core';
import { UiService } from '@fi-sas/webpage/core/services/ui.service';
import { AuthService } from '@fi-sas/webpage/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarOptionsChangeService {

  constructor(private uiService: UiService,
    private authService: AuthService) {

    this.authService.getIsLoggedObservable().subscribe(val => {
      this.optionsAccomodation(val);

    });

    this.optionsAccomodation(this.authService.getIsLogged());
  }

  optionsChange() {
    this.optionsAccomodation(this.authService.getIsLogged());
  }

  optionsAccomodation(isLogged = false) {
    if (isLogged) {
      // this.uiService.disableSideOption(false, 'HEALTH', 'HEALTH');
      // this.uiService.disableSideOption(false, 'REQUESTS', 'REQUESTS');
    } /*else {
      this.uiService.disableSideOption(true, 'ACCOMMODATION', 'RESIDENCES');
      this.uiService.disableSideOption(true, 'ACCOMMODATION', 'VIEW_APPLICATIONS');

      this.uiService.disableSideOption(false, 'PRIVATEACCOMMODATION', 'LIST');
      this.uiService.disableSideOption(false, 'PRIVATEACCOMMODATION', 'OWNER');

      this.uiService.disableSideOption(true, 'ALIMENTATION', 'FOOD_ALLERGIES');
      this.uiService.disableSideOption(true, 'ALIMENTATION', 'PURCHASED_TICKETS');

      this.uiService.disableSideOption(true, 'U_BIKE', 'APPLICATION');
    }*/
  }
}
