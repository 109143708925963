<nz-layout class="main">

  <!--Sidebar-->
  <nz-sider [nzCollapsed]="true">

    <nz-sider [nzWidth]="285" class="main__container__sidebar" [nzCollapsed]="$siderCollapsed | async">
      <app-layout-slide-menu></app-layout-slide-menu>
    </nz-sider>

  </nz-sider>

  <nz-layout class="main__container">

    <!--Header-->
    <nz-header class="main__header">
      <app-layout-header></app-layout-header>
    </nz-header>

    <!--Content-->
    <nz-content class="main__container__content">
      <router-outlet></router-outlet>
    </nz-content>

    <!--Footer-->
    <nz-footer id="footer23" class="main__container__footer">
      <app-layout-footer></app-layout-footer>
    </nz-footer>

  </nz-layout>

</nz-layout>