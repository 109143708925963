import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Resource } from "@fi-sas/core";

import { first, tap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { ConfigurationsService } from "./shared/services/configurations.service";

@Injectable()
export class AppResolver implements Resolve<Observable<Resource<any>>> {
  constructor(private configurationGeralService: ConfigurationsService) {}

  resolve(): any {
    localStorage.removeItem("configuraction_active_menu_wp");
    const services = [
      {
        translations: [
          {
            service_id: 1,
            name: "Dashboards",
            description: "Serviço de gestão de dashboards",
          },
          {
            service_id: 1,
            name: "Dashboards",
            description: "Dashboard managment service",
          },
        ],
        profiles: [],
        section_bo: {
          id: 2,
          name: "Serviços internos",
          priority: 2,
          updated_at: "2021-12-16T10:44:53.945Z",
          created_at: "2021-12-16T10:44:53.945Z",
        },
        id: 1,
        active: true,
        icon: null,
        updated_at: "2022-07-13T15:01:50.895Z",
        created_at: "2022-07-13T14:53:12.374Z",
      },
      {
        translations: [
          {
            service_id: 2,
            name: "Ideias de Projeto",
            description: "Ideias de Projeto",
          },
          {
            service_id: 2,
            name: "Project Ideas",
            description: "Project Ideas",
          },
        ],
        profiles: [],
        section_bo: {
          id: 2,
          name: "Serviços internos",
          priority: 2,
          updated_at: "2021-12-16T10:44:53.945Z",
          created_at: "2021-12-16T10:44:53.945Z",
        },
        id: 2,
        active: true,
        icon: null,
        updated_at: "2023-04-19T10:05:00.000Z",
        created_at: "2023-04-19T10:05:00.000Z",
      },
      {
        translations: [
          {
            service_id: 3,
            name: "Configurações",
            description: "Configurações",
          },
          {
            service_id: 3,
            name: "Configurations",
            description: "Configurations",
          },
        ],
        profiles: [],
        section_bo: {
          id: 2,
          name: "Serviços internos",
          priority: 2,
          updated_at: "2021-12-16T10:44:53.945Z",
          created_at: "2021-12-16T10:44:53.945Z",
        },
        id: 3,
        active: true,
        icon: null,
        updated_at: "2023-04-19T10:05:00.000Z",
        created_at: "2023-04-19T10:05:00.000Z",
      },
      {
        translations: [
          {
            service_id: 4,
            name: "Configurações",
            description: "Configurações",
          },
          {
            service_id: 3,
            name: "Configurations",
            description: "Configurations",
          },
        ],
        profiles: [],
        section_bo: {
          id: 2,
          name: "Serviços internos",
          priority: 2,
          updated_at: "2021-12-16T10:44:53.945Z",
          created_at: "2021-12-16T10:44:53.945Z",
        },
        id: 3,
        active: true,
        icon: null,
        updated_at: "2023-04-19T10:05:00.000Z",
        created_at: "2023-04-19T10:05:00.000Z",
      },
    ];
    localStorage.setItem(
      "configuraction_active_menu_wp",
      JSON.stringify(services)
    );
    // return this.configurationGeralService.geral().pipe(
    //   first(),
    //   tap((results) => {
    //     console.log(
    //       "%capp.resolver.ts line:52 object",
    //       "color: #007acc;",
    //       results.data[0].services
    //     );
    //     localStorage.setItem(
    //       "configuraction_active_menu_wp",
    //       JSON.stringify(results.data[0].services)
    //     );
    //   })
    // );
  }
}
