<div class="upload" *ngIf="!loadingFiles">
  <nz-upload [nzFilter]="filters" [nzDisabled]="disableUploadButton" [nzListType]="listType" [nzPreview]="handlePreview"
    [nzCustomRequest]="uploadFile" [nzRemove]="removeFile" [nzLimit]="limit" [nzFileList]="filesUploaded"
    [nzFileType]="filesTypes">

    <div class="upload-content" *ngIf="styleType != 'new'">
      <i nz-icon nzType="icons:icons-upload"></i>
      <div style="margin-top: 8px;margin-left: 8px;">{{ buttonName | translate }}</div>
    </div>

    <app-button style="margin-top:10px" *ngIf="styleType == 'new'"
    [translateContent]="label"
    [rightIcon]="icon"
    [type]="'primary'">
  </app-button>

  </nz-upload>
</div>
