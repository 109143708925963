<div nz-row class="user-header" nzJustify="end" nzAlign="middle">
  
  <!----------------------------REPORTS--------------------------->
  <app-layout-reports *ngIf="userModel != null"></app-layout-reports>

  <!------------------------NOFITICATIONS------------------------->
  <app-layout-notifications *ngIf="userModel != null"></app-layout-notifications>
  
  <!---------------------------------USER----------------------->
  <button nz-button nzType="link" [routerLink]="['/auth', 'login']" *ngIf="!userModel">
    <nz-avatar nzIcon="icons:icons-account" class="avatar"></nz-avatar>
    <span class="user-header___name">{{ userModel ? nameUser : "Login" }}</span>
  </button>
  <div nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click" [nzPlacement]="'bottomRight'" *ngIf="userModel">
    <button nz-button nzType="link">
      <nz-avatar nzIcon="icons:icons-account" class="avatar"></nz-avatar>
      <span class="user-header___name">{{
        userModel ? nameUser : "Login"
        }}</span>
    </button>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu class="user-info">
      <li nz-menu-item>
        <div nz-row nzJustify="end">
          <div nz-col [nzSpan]="8">
            <nz-avatar nzIcon="icons:icons-account" nzSize="large" class="avatar"></nz-avatar>
          </div>

          <div nz-col [nzSpan]="16">
            <div nz-row class="user-info___name">
              <div nz-col [nzSpan]="24">
                {{ nameUser }}
              </div>
            </div>

            <div nz-row class="user-info___email">
              <div nz-col [nzSpan]="24">
                {{ userModel?.email }}
              </div>
            </div>
          </div>
        </div>
      </li>
      <li nz-menu-item>
        <a (click)="profile()">{{ "CORE.PROFILE" | translate }}</a>
      </li>
      <li nz-menu-divider *ngIf="showQrCodeLink"></li>
      <li nz-menu-item (click)="qrcode()" *ngIf="showQrCodeLink">
        {{ "QRCODE.TITLE" | translate }}
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item (click)="logout()">{{ "CORE.LOGOUT" | translate }}</li>
    </ul>
  </nz-dropdown-menu>
</div>