import { environment } from "../environments/environment";

export const WP_CONFIGURATION = {
  DEFAULT_LANG_ID: 3,
  DEFAULT_CATEGORY_ID: 1,
  DOMAINS_API: [
    { HOST: environment.host, KEY: "@api_gateway" },
    { HOST: environment.auth_api_url, KEY: "@api_auth" },
    { HOST: environment.conf_api_url, KEY: "@api_configuration" },
    { HOST: environment.media_api_url, KEY: "@api_media" },
    { HOST: environment.media_url, KEY: "@api_media_download" },
    { HOST: environment.alimentation_api_url, KEY: "@api_alimentation" },
    { HOST: environment.current_account_api_url, KEY: "@api_current_account" },
    { HOST: environment.reports_api_url, KEY: "@api_reports" },
    { HOST: environment.notifications_api_url, KEY: "@api_notifications" },
    { HOST: environment.communication_api_url, KEY: "@api_communication" },
    { HOST: environment.calendar_api_url, KEY: "@api_calendar" },
    { HOST: environment.requests_api_url, KEY: "@api_requests" },
    { HOST: environment.crm_api_url, KEY: "@api_crm" },


    { HOST: "/assets/data", KEY: "@local" },
  ],
  ENDPOINTS: {
    REPORTS: {
      REPORTS: "@api_reports:/reports",
      REPORT_ID: "@api_reports:/reports/:id",
      REPORTS_READ: "@api_reports:/reports/:id/read",
    },
    NOTIFICATIONS: {
      NOTIFICATIONS_INTERNAL: "@api_notifications:/notifications/internal",
      NOTIFICATIONS_ID: "@api_notifications:/notifications/:id",
      NOTIFICATIONS_READ: "@api_notifications:/notifications/:id/read",
    },
    USERS: {
      CHANGE_PASSWORD: "@api_auth:/users/change-password",
      RANDOM_PIN: "@api_auth:/users/randomPin",
      CHANGE_PIN: "@api_auth:/users/change-pin",
      FIRST_LOGIN: "@api_auth:/users/first-login",
      UPDATE_ME: "@api_auth:/users/update-me",
      RESET_PIN: "@api_auth:/users/reset-pin",
      CONFIRM_PIN: "@api_auth:/users/confirm-pin",
      RESET_PASSWORD: "@api_auth:/users/reset-password",
      NEW_PASSWORD: "@api_auth:/users/new-password",
      USERS: "@api_auth:/users",
      USERS_ID: "@api_auth:/users/:id",
      VERIFY_ACCOUNT: "@api_auth:/users/verify_account",
      NEW_VERIFICATION_CODE: "@api_auth:/users/create_account_verify",
      USER_DISABLE: "@api_auth:/users/disable-account",
      DOCUMENT_TYPES: "@api_auth:/document-types",
    },
    CONFIGURATION: {
      COURSE_DEGREES: "@api_configuration:/course-degrees",
      COURSES: "@api_configuration:/courses",
      CONTACTS: "@api_configuration:/contacts",
      GERAL: "@api_configuration:/configuration",
      GERALSERVICE_ID: "@api_configuration:/services/:id",
      LANGUAGES: "@api_configuration:/languages",
      LANGUAGES_ID: "@api_configuration:/languages/:id",
      TEXTAPRESENTATION: "@api_configuration:/apresentation_texts",
      FORM_INFORMATION: "@api_configuration:/form_informations",
      DASHBOARD: "@api_configuration:/dashboard_user",
      TERMS: "@api_configuration:/terms",
      PRIVACY_POLICIES: "@api_configuration:/privacy_policies",
      INFORMATIONS: "@api_configuration:/informations",
      TERMS_ID: "@api_configuration:/terms/:id",
      PRIVACY_POLICIES_ID: "@api_configuration:/privacy_policies/:id",
      INFORMATIONS_ID: "@api_configuration:/informations/:id",
      DEVICES: "@api_configuration:/devices",
      DEVICES_ID: "@api_configuration:/devices/:id",
      ACADEMIC_YEARS: "@api_configuration:/academic_years",
      CURRENT_ACADEMIC_YEAR: "@api_configuration:/academic_years/current",
    },
    AUTH: {
      LOGIN: "@api_auth:/authorize/device-type/WEB",
      LOGIN_USER: "@api_auth:/authorize/user",
      LOGOUT: "@api_auth:/authorize/logout/:type",
      REFRESH_TOKEN: "@api_auth:/authorize/refresh-token/:type",
      VALIDATE_TOKEN: "@api_auth:/authorize/validate-token",
      SSO_URL: "@api_auth:/authorize/sso/url",
      SSO_CC_URL: "@api_auth:/authorize/sso-cc/url",
    },
    MEDIA: {
      UPLOAD: "@api_media_download:/",
      FILE_DOWNLOAD: "@api_media_download:/:path",
      FILE: "@api_media:/files",
      FILE_ID: "@api_media:/files/:id",
      FILE_UPLOAD: "@api_media:/files/upload",
    },
    INCLUSIVE_SCHOOL: {
      PROJECT: "@api_crm:/inclusive_school/project",
      PROJECT_LIST: "@api_crm:/inclusive_school/:company_id/project",
      PROJECT_GET: "@api_crm:/inclusive_school/:company_id/project/:project_id",
      PROJECT_DELETE: "@api_crm:/inclusive_school/:company_id/project/:project_id",
      PROJECT_ID: "@api_crm:/inclusive_school/project/:id_projeto",
      PROJECT_TYPE: "@api_crm:/inclusive_school/project_types",
      EVALUATE_COLLABORATION: "@api_crm:/inclusive_school/:id_instituicao/project/:id_projeto/collaboration/:id_colaboracao",
      SCHOOL: "@api_crm:/inclusive_school/schools",
      SCHOOL_YEAR: "@api_crm:/inclusive_school/school_years",
      ADDRESSEES: "@api_crm:/inclusive_school/addressees",
      ODS: "@api_crm:/inclusive_school/ods",
      COURSE: "@api_crm:/inclusive_school/schools/:school_id/grades/:grade/courses/:year",
      INSTITUITION: "@api_crm:/inclusive_school/instituition",
      GRADE: "@api_crm:/inclusive_school/schools/:school_id/grades/:year",
    },
    JOB_PORTAL: {
      CONFIGS: {
        INTERVIEW_TYPES: "@api_crm:/company/typeInterviews",
        WORK_MODALITY: "@api_crm:/company/modalityWork",
        LANGUAGE_LEVEL: "@api_crm:/company/levels",
        LITERARY_HABILITATIONS: "@api_crm:/company/literaryHabilitations",
        PROFESSIONAL_EXPERIENCES: "@api_crm:/company/professionalExperience",
        PROFESSIONAL_SECTORS: "@api_crm:/sub_sector",
        CONTRACT_TYPE: "@api_crm:/company/contract_type",
        TYPE_HORARY: "@api_crm:/company/horary_types",
        SALARY_TIER: "@api_crm:/company/salary_scale",
        INTERNSHIP_TYPE: "@api_crm:/company/internship_types",
        NUMBER_DAYS_OFFER_EXPIRE: "@api_crm:/company/days_offer_expire",
        NUMBER_DAYS_OFFER_EXPIRE_FAIR: "@api_crm:/company/number_days_after_offer_expire",
        DISTRICTS: "@api_crm:/company/districts",
        MUNICIPALITIES: "@api_crm:/company/municipalities/:district",
        PROFESSIONS: "@api_crm:/company/professions_INE",
      },
      JOB_OFFERS: {
        JOB_OFFER: "@api_crm:/company/job_offers",
        JOB_OFFER_ID: "@api_crm:/company/job_offers/:id",
        APPLICATIONS_ACTIVE: "@api_crm:/company/jobApplications/actives/:offer_id",
        APPLICATIONS_DISABLED: "@api_crm:/company/jobApplications/disabled/:offer_id",
        APPLICATION_INTERVIEW: "@api_crm:/company/updateJobApplication/:id",
        CHANGE_STATUS: "@api_crm:/company/changeStatusJobOffer",
        REOPEN: "@api_crm:/company/reOpenJobOffer",
      },
      INTERNSHIP_OFFERS: {
        JOB_INTERNSHIP: "@api_crm:/company/internship_offers",
        JOB_INTERNSHIP_ID: "@api_crm:/company/internship_offers/:id",
        CHANGE_STATUS: "@api_crm:/company/changeStatusInternshipOffer",
        REOPEN: "@api_crm:/company/reOpenInternshipOffer",
      },
      EMPLOYMENT_EVENTS: {
        EMPLOYMENT_EVENT_ID: "@api_crm:/company/employmentEvents/:id",
        EMPLOYMENT_EVENT: "@api_crm:/company/employmentEvents",
        DASHBOARD: "@api_crm:/company/employmentDashboard",
        OFFERS_LIST: "@api_crm:/company/offersByEmploymentEvent/:id",
        APPLICATION: "@api_crm:/company/application-employment-event",
        CREATEOFFERS: "@api_crm:/company/employment-event/:id/offers",
        DELETEINTERNSHIPOFFER: "@api_crm:/company/deleteInternshipOffer/:id",
        APPLICATIONRETURN: "@api_crm:/company/employmentEvents/:id/application",
        DELETEJOBOFFER: "@api_crm:/company/deleteJobOffer/:id",
        OFFERTOJOBPORTAL: "@api_crm:/company/offer/:id/sendToJobPortal",
      },
      CANDIDATES: {
        CANDIDATES_ID: "@api_crm:/company/candidates/:id",
        LIST_CANDIDATES: "@api_crm:/company/candidates",
        COURSES_BY_CANDIDATE: "@api_crm:/company/candidates/courses",
        DEGREES_BY_CANDIDATE: "@api_crm:/company/candidates/degrees",
      },
      COMPANY_USER_LOGGED: "@api_job_portal:/companies/profile/:id",
      COMPANY: "@api_job_portal:/company",
      COMPANY_ID: "@api_job_portal:/companies/:id",
      COMPANY_UPDATE: "@api_crm:/company/updateCompany/:id",
      SECTORS: "@api_job_portal:/sector",
      SKILLS: "@api_job_portal:/skills",
      SOFT_SKILLS: "@api_job_portal:/soft_skills",
      IDIOMS: "@api_job_portal:/idioms",
    },
    CRM: {
      VERIFY_ACCOUNT: "@api_crm:/company/verify_account",
      COMPANY: "@api_crm:/company",
      COMPANY_ID: "@api_crm:/company/:id",
      COMPANY_USER_LOGGED: "@api_crm:/company/byUser",
      COMPANY_CREATE: "@api_crm:/company/portal",
      DASHBOARDS: "@api_crm:/company/companiesDashboard",
      CAES: "@api_crm:/sub_sector/cae",
      CAE_BY_KEY: "@api_crm:/sub_sector/cae/:key",
      SECTOR: "@api_crm:/sector",
      SECTOR_ID: "@api_crm:/sector/:id",
      SUB_SECTOR: "@api_crm:/sub_sector",
      SUB_SECTOR_ID: "@api_crm:/sub_sector/:id",
      SURVEY: "@api_crm:/survey",
      SURVEY_ID: "@api_crm:/survey/:id",
      CREATE_JOB_OFFER: "@api_crm:/company/job_offer",
      NEW_VERIFICATION_CODE: "@api_crm:/company/create_account_verify",
      RESET_PASSWORD: "@api_crm:/company/reset-password",
    }


  },
  LANGUAGES: [
    {
      acronym: "pt",
      id: 3,
    },
    {
      acronym: "en",
      id: 4,
    },
  ],
  ERROR_TRANSLATOR: {
    NOT_FOUND_MESSAGE: "Ocorreu um erro desconhecido",
    REFRESH_PAGE: "Ocorreu um erro, por favor actualize a página",
    SERVICE_DOWN: "Serviço temporáriamente indisponível",
  },
};
