import { Injectable } from '@angular/core';
import { FiResourceService, FiUrlService, Resource } from '@fi-sas/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { CourseModel } from '@fi-sas/webpage/shared/models/course.model';
import { SchoolModel } from '@fi-sas/webpage/shared/models/school.model';
import { CourseDegreeModel } from '@fi-sas/webpage/shared/models/course-degree.model';
import { ContactModel } from '@fi-sas/webpage/shared/models/contact.model';
import { TextApresentationsModel } from '../models/text-apresentation.model';
import { AcademicYear } from '../models';
import { FormInformationTextsModel } from '../models/form-information-texts.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsService {

  constructor(private resourceService: FiResourceService,
    private urlService: FiUrlService) { }


  //  withRelated - school
  //              - courseDegree
  courses(course_degree_id?: number, organic_unit_id?: number, id?: number): Observable<Resource<CourseModel>> {
    let params = new HttpParams();
    params = params.set('offset', '0');
    params = params.set('limit', '-1');
    params = course_degree_id ? params.set('query[course_degree_id]', course_degree_id.toString()) : params;
    params = organic_unit_id ? params.set('query[organic_unit_id]', organic_unit_id.toString()) : params;
    params = id ? params.set('query[id]', id.toString()) : params;


    return this.resourceService.list<CourseModel>(this.urlService.get('CONFIGURATION.COURSES'), { params });
  }

  //  withRelated - courses
  //              - courses.school
  courseDegrees(id?: number): Observable<Resource<CourseDegreeModel>> {
    let params = new HttpParams();
    params = params.set('offset', '0');
    params = params.set('limit', '-1');
    params = id ? params.set('query[id]', id.toString()) : params;
    params = params.set('sort', 'name');
    return this.resourceService.list<CourseDegreeModel>(this.urlService.get('CONFIGURATION.COURSE_DEGREES'), { params });
  }

  contacts(): Observable<Resource<ContactModel>> {
    let params = new HttpParams();
    params = params.set('offset', '0');
    params = params.set('limit', '-1');

    return this.resourceService.list<ContactModel>(this.urlService.get('CONFIGURATION.CONTACT'), { params });
  }

  geral(): Observable<Resource<any>> {
    let params = new HttpParams();
    params = params.set('offset', '0');
    params = params.set('limit', '-1');

    return this.resourceService.list<any>(this.urlService.get('CONFIGURATION.GERAL'), { params });
  }

  geralService(id: number): Observable<Resource<any>> {
    let params = new HttpParams();
    params = params.set('offset', '0');
    params = params.set('limit', '-1');

    return this.resourceService.list<any>(this.urlService.get('CONFIGURATION.GERALSERVICE_ID', { id }),);
  }

  listTextApresentations(id_Service: number): Observable<Resource<TextApresentationsModel>> {
    let params = new HttpParams();
    params = params.set('query[service_id]', id_Service.toString());


    return this.resourceService.list<TextApresentationsModel>(this.urlService.get('CONFIGURATION.TEXTAPRESENTATION'), { params });
  }

  getLocalStorageService(id: number) {
    const item = localStorage.getItem('configuraction_active_menu_wp')
    let data = item ? JSON.parse(item) : null;
    if (data) {
      const service = data.find((service: any) => service.id == id);
      return service;
    }
    return [];
  }

  getLocalStorageTotalConfiguration() {
    const item = localStorage.getItem('configuraction_active_menu_wp')
    return item ? JSON.parse(item) : null;
  }

  getAcademicYears(): Observable<Resource<AcademicYear>> {
    let params = new HttpParams();
    params = params.set('offset', '0');
    params = params.set('limit', '-1');
    return this.resourceService.list<AcademicYear>(this.urlService.get('CONFIGURATION.ACADEMIC_YEARS'), { params })
  }

  getCurrentAcademicYear(): Observable<Resource<AcademicYear>> {
    let params = new HttpParams();
    params = params.set('offset', '0');
    params = params.set('limit', '1');
    return this.resourceService.list<AcademicYear>(this.urlService.get('CONFIGURATION.CURRENT_ACADEMIC_YEAR'), { params })
  }

  getInfoForms(service_id: number): Observable<Resource<FormInformationTextsModel>> {
    let params = new HttpParams();
    params = params.set('query[service_id]', service_id);
    params = params.set('limit', "-1");
    return this.resourceService.list<FormInformationTextsModel>(this.urlService.get('CONFIGURATION.FORM_INFORMATION'), { params });
  }
}
