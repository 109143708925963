import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { filter } from 'rxjs';

export enum FooterLogoType {
  AMA = "AMA",
  PRR = "PRR",
};

@Component({
  selector: 'app-layout-footer',
  templateUrl: './layout-footer.component.html',
  styleUrls: ['./layout-footer.component.less']
})
export class LayoutFooterComponent implements OnInit {

  current_date = new Date();
  version = environment.version;

  FooterLogoType = FooterLogoType;
  logo_type: FooterLogoType = FooterLogoType.AMA;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {

    // subscribe to the NavigationEnd event
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.updateData();
      });

    this.updateData();
  }

  ngOnInit() {
  }

  private updateData() {
    let root = this.activatedRoute.root
    let last_footer = FooterLogoType.AMA;

    if (root.snapshot.data["footer"]) {
      last_footer = root.snapshot.data["footer"];
    }

    while (true) {
      if (!root.children) {
        break;
      }
      const children = root.children;


      if (children.length == 0) {
        break;
      }

      if (children[0].outlet !== PRIMARY_OUTLET) {
        continue;
      }

      if (children[0].snapshot && children[0].snapshot.data["footer"]) {
        last_footer = children[0].snapshot.data["footer"];
      }

      root = children[0];
    }

    this.logo_type = last_footer;
  }

  get isInclusiveSchoolRoute(): boolean {
    return this.router.url.includes("/inclusive_school")
  }
  get isJobPortalRoute(): boolean {
    return this.router.url.includes("/job-portal")
  }
  get isCRMRoute(): boolean {
    return this.router.url.includes("/surveys")
  }
}
