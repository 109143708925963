import { Component, Input, OnInit } from '@angular/core';

export enum ButtonType {
  Primary = 'primary',
  Secundary = 'secundary',
  Tertiary = 'tertiary',
  Floating = 'floating',
  Red = 'red',
  InfoBlue = 'infoblue'
}
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.less']
})
export class ButtonComponent implements OnInit {
  public ButtonType = ButtonType;
  @Input() translateContent = 'DEFAULT_VALUES.BUTTON_NAME';
  // Colors -> institute
  @Input() type: 'primary' | 'secundary' | 'tertiary' | 'floating' | 'red' | 'infoblue' = ButtonType.Primary;
  @Input() loading = false;
  @Input() buttonStyle = {};
  @Input() leftIcon = null;
  @Input() rightIcon = null;
  @Input() disabled = false;


  constructor() { }

  ngOnInit() { }

}
