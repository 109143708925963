<!--SideBar Button-->
<nz-header class="sidebar-fold">
  <!--Sider Button-->
  <div nz-col class="sidebar-fold__button" (click)="tiggerSider()">
    <i nz-icon nzType="icons:icons-menu" nzTheme="outline"></i>
  </div>

  <div [routerLink]="['/']" nz-col class="sidebar-fold__title" *ngIf="!($siderCollapsed | async)">
    <img  alt="Logo Portal Emprego" [src]="'./assets/logos/white/IPVC.svg?v1'">
  </div>

</nz-header>

<!--Sidebar Menu-->
<ul nz-menu class="sidebar" [nzTheme]="'light'" [nzMode]="'inline'" [nzInlineCollapsed]="$siderCollapsed | async"
  style="border: none;">

  <ng-container *ngFor="let item of sideItemsFilter">

    <li class="sidebar__options" nz-submenu [class.disabled]='item.disable'>
      <span title (click)="selectModule(item)">
        <nz-badge *ngIf="item.serviceId >= 2" class="badge-menu-start" [nzCount]="total">
          <i nz-icon class="icon-menu-start" [nzType]="item?.icon"></i>
        </nz-badge>
        <i *ngIf="item.serviceId < 2" nz-icon class="icon-menu-start" [nzType]="item?.icon"></i>

        <span class="nav-text sidebar__options__text">{{item.name | translate}}</span>

      </span>
      <ul>
        <ng-container *ngFor="let children of item.children">
          <li *ngIf="children.visible" nz-menu-item
            [class.disabled]='children.disable' [routerLink]="[children.link]">{{children.name | translate}}</li>
        </ng-container>
      </ul>
    </li>

  </ng-container>
  

  <li nz-menu-item id="lang-padding" class="sidebar__lang">
    <div nz-dropdown [nzDropdownMenu]="dropdownMenu">
      <a>
        <i nz-icon nzType="icons:icons-language"></i>
        <span *ngIf="!($siderCollapsed | async)" class="nav-text sidebar__lang__text">{{ 'CORE.LANGUAGES.' +
          translate.currentLang | translate }}</span>
      </a>
    </div>
    <nz-dropdown-menu #dropdownMenu>
      <ul nz-menu nzSelectable>
        <li nz-menu-item *ngFor="let lang of translate.getLangs()" (click)="langChange(lang)"
          [nzSelected]="translate.currentLang === lang">
          {{ 'CORE.LANGUAGES.' + lang | translate }}
        </li>
      </ul>
    </nz-dropdown-menu>
  </li>
</ul>