import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import Swal from "sweetalert2";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
  onConfirm: any;
}

@Injectable()
export class ComponentCanDeactivateGuard
  implements CanDeactivate<ComponentCanDeactivate>
{
  canDeactivate(
    component: ComponentCanDeactivate
  ): boolean | Observable<boolean> {
    if (component.canDeactivate()) {
      return true;
    } else {
      return component.onConfirm();
    }
  }
}
