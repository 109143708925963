import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@fi-sas/webpage/auth/services/auth.service';
import { UserModel } from '@fi-sas/webpage/auth/models/user.model';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout-header-user',
  templateUrl: './layout-header-user.component.html',
  styleUrls: ['./layout-header-user.component.less']
})
export class LayoutHeaderUserComponent implements OnInit {

  userModel: UserModel = null;
  nameUser: string;

  showQrCodeLink = environment.showQrCodeLink;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    this.authService.getUserObservable().subscribe(user => {
      if (this.authService.getIsLogged()) {
        this.userModel = user;
        if(this.userModel) {
          this.nameUser = this.userModel.name.split(' ')[0];
        }
        
      } else {
        this.userModel = null;
      }
    });

    if (this.authService.getIsLogged()) {
      this.userModel = this.authService.getUser();
      if(this.userModel) {
        this.nameUser = this.userModel.name.split(' ')[0];
      }
    }
  }

  ngOnInit() {
  }

  qrcode()  {
    this.router.navigate(['qrcode']);
  }

  profile(){
    this.router.navigate(['profile']);
  }

  logout() {
    this.authService.logout().pipe(first()).subscribe((data) => {
      this.router.navigate(['auth', 'login']);
    });
  }
}
