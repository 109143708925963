import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FiResourceService, FiUrlService, Resource } from '@fi-sas/core';
import { FileUploadModel } from '@fi-sas/webpage/modules/media/models/file-upload.model';
import { FileModel } from '@fi-sas/webpage/modules/media/models/file.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private resourceService: FiResourceService,
              private urlService: FiUrlService) { }


  createFile(file: FormData): Observable<Resource<FileModel>> {
    return this.resourceService.create<FileModel>(this.urlService.get('MEDIA.FILE', {}), file);
  }

  uploadFile(file: FileUploadModel): Observable<Resource<FileUploadModel>> {
    return this.resourceService.create<FileUploadModel>(this.urlService.get('MEDIA.FILE_UPLOAD', {}), file);
  }

  removeFile(id: number): any {
    return this.resourceService.delete(this.urlService.get('MEDIA.FILE_ID', {id}));
  }

  file(id: number): Observable<Resource<FileModel>> {
    return this.resourceService.read<FileModel>(this.urlService.get('MEDIA.FILE_ID', { id }), {});
  }

  listfiles(ids: number[]): Observable<Resource<FileModel>> {
    let httpParams = new HttpParams();
    ids.forEach(id => {
      httpParams = httpParams.append('query[id]', id.toString());
    });
    httpParams = httpParams.append('limit', '-1');
    return this.resourceService.list<FileModel>(this.urlService.get('MEDIA.FILE', {}), {params: httpParams });
  }
}
