import { Component, OnInit } from "@angular/core";
import { CurrentAccountsService } from "../../services/current-accounts.service";

@Component({
    selector: 'app-layout-current-account',
    templateUrl: './current-account.component.html',
    styleUrls: ['./current-account.component.less']
})

export class LayoutCurrentAccountComponent implements OnInit {
    visibleCurrentAccounts: boolean;
    loading = true;
    $currentAccounts = null;

    constructor(private currentAccountsService: CurrentAccountsService) { }

    ngOnInit() {
        this.getCurrentAccounts();
    }

    clickMe(): void {
        this.visibleCurrentAccounts = false;
    }

    getCurrentAccounts() {
        this.$currentAccounts = null;
        this.$currentAccounts = this.currentAccountsService
            .getCurrentAccountObservable();
        this.loading = false;
    }



    refresh() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
            this.currentAccountsService.loadData();
        }, 200);
    }




}