import { SiderItem } from "./../../models/sider-item";
import { UiService } from "@fi-sas/webpage/core/services/ui.service";
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { first } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { SidebarOptionsChangeService } from "@fi-sas/webpage/core/services/sidebar-options-change.service";
import { environment } from "src/environments/environment";
import { ConfigurationsService } from "@fi-sas/webpage/shared/services/configurations.service";
import { AuthService } from "@fi-sas/webpage/auth/services/auth.service";
import { Router } from "@angular/router";
import { FiConfigurator } from "@fi-sas/configurator";

@Component({
  selector: "app-layout-slide-menu",
  templateUrl: "./layout-slide-menu.component.html",
  styleUrls: ["./layout-slide-menu.component.less"],
})
export class LayoutSlideMenuComponent implements OnInit, AfterContentChecked {
  dataConfiguration;
  public $siderCollapsed = null;
  total = 0;
  institute = null;
  sideItemsFilter: SiderItem[] = [];
  externalServicesConfig = [];
  externalServices = [];
  languages: any;

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private configurationsService: ConfigurationsService,
    private sidebarOptionsChange: SidebarOptionsChangeService,
    private uiService: UiService,
    public translate: TranslateService,
    private router: Router,
    private config: FiConfigurator
  ) {
    this.institute = environment.institute;
    this.$siderCollapsed = this.uiService.siderObservable();
    this.languages = Object.values(config.getOptionTree("LANGUAGES"))[0];
  }

  ngOnInit() {
    this.dataConfiguration =
      this.configurationsService.getLocalStorageTotalConfiguration();
    this.uiService
      .getSiderItemsObservable()
      .pipe(first())
      .subscribe((value) => {
        this.validaService(value);
        //this.siderItems = value.filter(si => !si.disable);
        //      this.sidebarOptionsChange.optionsChange();
      });

    // this.addExternalServices();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  tiggerSider() {
    this.uiService.triggerSider();
  }

  validaService(_value: SiderItem[]) {
    let logged = this.authService.getIsLogged();

    let sideItemsFilter = Object.assign([], this.uiService.getSiderItems());
    // if (logged) {
    //   sideItemsFilter = sideItemsFilter.filter((fil) => this.dataConfiguration.find(service => service.id == fil.serviceId && service.active == true && this.authService.hasPermission(fil.scope as string)));
    // } else {
    //   sideItemsFilter = sideItemsFilter.filter((fil) => this.dataConfiguration.find(service => service.id == fil.serviceId && service.active == true));
    // }

    if (!logged) sideItemsFilter = [];

    sideItemsFilter = sideItemsFilter.filter(
      (fil) =>
        (!fil.scope ||
          (fil.scope && this.authService.hasPermission(fil.scope))) &&
        this.dataConfiguration.find(
          (service) => service.id == fil.serviceId && service.active == true
        )
    );

    for (const item of sideItemsFilter) {
      // item.name = this.dataConfiguration.find(
      //   (service) => service.id == item.serviceId
      // )
      //   ? this.dataConfiguration.find((service) => service.id == item.serviceId)
      //       .translations
      //   : "";

      const sideItem = Object.assign({}, item);
      sideItem.children = [];

      for (const child of item.children) {
        let hasScope = false;
        let hasService = false;

        if (!child.scope) {
          hasScope = true;
        } else {
          if (typeof child.scope === "string") {
            hasScope = this.authService.hasPermission(child.scope);
          }

          if (Array.isArray(child.scope)) {
            hasScope = child.scope.every((scope) =>
              this.authService.hasPermission(scope)
            );
          }
        }

        if (!child.serviceId) {
          hasService = true;
        } else {
          hasService = !!this.dataConfiguration.find(
            (service) =>
              service.active === true && service.id == child.serviceId
          );
        }

        // MUST HAVE SCOPE AND SERVICE ACTIVE
        child.visible = hasScope && hasService;

        sideItem.children.push(Object.assign({}, child));
      }

      this.sideItemsFilter.push(sideItem);
    }
  }

  addExternalServices() {
    this.externalServicesConfig = this.dataConfiguration.filter(
      (data) =>
        data.is_external_service && data.active && data.webpage_availability
    );
    this.externalServicesConfig.forEach((external) =>
      this.externalServices.push({
        name: external.translations,
        link: external.external_link,
        icon: external.icon ? "icons:" + external.icon : "icons:icons-external",
        profiles: external.profiles,
        active: true,
        external: true,
      })
    );

    const user = this.authService.getUser();
    if (user) {
      //this.externalServices.sort((a, b) =>a.name.find(nA => nA.language_id === this.getCurrentLanguage()).description > b.name.find(nB => nB.language_id === this.getCurrentLanguage()).description ? 1 : -1 );

      this.externalServices.sort((a, b) => {
        const nameA = a.name.find(
          (nA) => nA.language_id === this.getCurrentLanguage()
        )
          ? a.name.find((nA) => nA.language_id === this.getCurrentLanguage())
              .description
          : null;
        const nameB = b.name.find(
          (nB) => nB.language_id === this.getCurrentLanguage()
        )
          ? b.name.find((nB) => nB.language_id === this.getCurrentLanguage())
              .description
          : null;
        return nameA && nameB ? (nameA > nameB ? 1 : -1) : -1;
      });

      this.externalServices.forEach((external) => {
        if (external.profiles.length) {
          if (external.profiles.find((p) => p.profile_id === user.profile_id)) {
            this.sideItemsFilter.push(external);
          }
        } else {
          this.sideItemsFilter.push(external);
        }
      });
    }
  }

  selectModule(module) {
    if (!module.external) {
      this.router.navigate([module.link]);
    } else {
      window.open(module.link, "_blank");
    }
  }

  langChange(lang) {
    this.translate.use(lang);
  }

  getCurrentLanguage() {
    const currentLang = this.languages.find(
      (lang) => lang.acronym === this.translate.currentLang
    );
    return currentLang.id;
  }
}
