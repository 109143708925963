import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FiConfigurator } from '@fi-sas/configurator';
import { Subject, takeUntil } from 'rxjs';

export interface TranslationModel {
  language_id: number;
  name: string;
  description?: string;
}

@Pipe({
  name: 'languageChange',
  pure: false,
})
export class LanguageChangePipe implements PipeTransform, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();


  currentLang: { acronym: string, id: number } | undefined = undefined;
  languagesIDS: { acronym: string, id: number }[] = [];

  constructor(private translate: TranslateService,
    private ref: ChangeDetectorRef,
    config: FiConfigurator) {

    if (config !== undefined) {
      this.languagesIDS = config.getOptionTree<{ acronym: string, id: number }[]>('LANGUAGES')['LANGUAGES'];
    } else {
      this.languagesIDS = [];
    }

    translate.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.languagesIDS !== undefined) {
          this.currentLang = this.languagesIDS.find(lang => lang.acronym === this.translate.currentLang);
        } else {
          this.currentLang = undefined;
        }
        //this.currentLang = this.currentLang !== undefined ? this.currentLang.id : 0;
        //if (!this.ref['destroyed']) {
        if (this.ref) {
          this.ref.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  transform(translations: Partial<TranslationModel>[]): any {
    let translateObject;

    if (this.languagesIDS) {
      this.currentLang = this.languagesIDS.find(lang => lang.acronym === this.translate.currentLang);
    } else {
      this.currentLang = undefined;
    }

    //this.currentLang = this.currentLang !== undefined ? this.currentLang.id : 0;

    if (translations !== null && translations != undefined) {
      translateObject = translations.find(trans => this.currentLang ? trans.language_id === this.currentLang.id : false);
      if (translateObject !== undefined) {
        return translateObject;
      } else {
        return translations[0];
      }
    } else {
      return { acronym: 'none', id: 0 };
    }
  }

}
