import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  static TITLE_PREFIX = 'Portal das Entidades';
  static TITLE_SEPARATOR = ' - ';
  static DESCRIPTION_MAX_LENGTH = 160;

  constructor(private metaService: Meta, private titleService: Title) { }

  update(title?: string, description?: string) {
    this.setTitle(title);
    this.setDescription(description);
    if (environment.production === false) {
      this.metaService.updateTag({ name: 'robots', content: 'noindex' })
    }
  }

  private setTitle(title?: string) {
    const titleParts = [SeoService.TITLE_PREFIX];
    if (environment.instituteFull) {
      titleParts.push(environment.instituteFull);
    }
    if (title) {
      titleParts.push(title);
    }
    this.titleService.setTitle(titleParts.join(SeoService.TITLE_SEPARATOR));
  }

  private setDescription(description?: string) {
    if (!description) {
      description = environment.institueDescription;
    }

    // As seen in https://moz.com/learn/seo/meta-description
    this.metaService.updateTag({
      name: 'description',
      content: description.replace(/[\n\t\r]/g, '').substr(0, SeoService.DESCRIPTION_MAX_LENGTH),
    });
  }
}
