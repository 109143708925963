// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const packageVersion = require("../../package.json");
const gateway_url = "https://si-techdev.ipvc.pt";

export const environment = {
  production: false,
  version: packageVersion.version,
  institute: "DEV",
  instituteFull: "Instituto de Desenvolvimento",
  institueDescription: "Exemplo de descrição do instituto",
  hasSSO: false,
  hasCC: false,
  useLDAP: false,

  showQrCodeLink: true,

  mobility_regulation_url:
    "http://portal.ipvc.pt/images/ipvc/sas/pdf/bus_regulamento.pdf",

  host: gateway_url,
  version_check_url: gateway_url.concat("/empresas/assets/version.json"),
  auth_api_url: gateway_url + "/api/authorization",
  accommodation_api_url: gateway_url + "/api/accommodation",
  private_accommodation_api_url: gateway_url + "/api/private_accommodation",
  payments_api_url: gateway_url + "/api/payments",
  media_api_url: gateway_url + "/api/media",
  media_url: gateway_url + "/media",
  alimentation_api_url: gateway_url + "/api/alimentation",

  current_account_api_url: gateway_url + "/api/current_account",
  social_support_api_url: gateway_url + "/api/social_scholarship",
  volunteering_api_url: gateway_url + "/api/volunteering",
  mentoring_api_url: gateway_url + "/api/mentoring",
  u_bike_api_url: gateway_url + "/api/u_bike",
  conf_api_url: gateway_url + "/api/configuration",
  infrastructure_api_url: gateway_url + "/api/infrastructure",
  mobility_api_url: gateway_url + "/api/bus",
  reports_api_url: gateway_url + "/api/reports",
  notifications_api_url: gateway_url + "/api/notifications",
  queues_api_url: gateway_url + "/api/queue",
  communication_api_url: gateway_url + "/api/communication",
  health_api_url: gateway_url + "/api/health",
  calendar_api_url: gateway_url + "/api/calendar",
  emergency_fund_api_url: gateway_url + "/api/emergency_fund",
  requests_api_url: gateway_url + "/api/requests",
  ugp_api_url: gateway_url + "/api/sitech",
  crm_api_url: gateway_url + "/api/crm",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
