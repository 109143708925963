import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { IConfig, NgxMaskModule } from 'ngx-mask';

import { LogoComponent } from './helpers/logo/logo.component';
import { OPTIONS_TOKEN } from '@fi-sas/configurator';
import { WP_CONFIGURATION } from './../app.config';

import {
  DataUnitsPipe,
  EuroCurrenyPipe,
  HasPermissionPipe,
  HourConvertPipe,
  LanguageChangePipe,
  LocalizedDatePipe,
  ReplacePipe,
  YesOrNoPipe,
} from './pipes';
import { GenderBeautifyPipe } from './pipes/gender-beautify.pipe';
import { GetFromObjectsArrayPipe } from './pipes/get-from-objects-array.pipe';
import { NZ_I18N, pt_PT } from 'ng-zorro-antd/i18n';
import { TagComponent } from './components/tag/tag.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { FileUploadModule } from './components/file-upload/file-upload.module';


export let options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    DataUnitsPipe,
    EuroCurrenyPipe,
    GenderBeautifyPipe,
    GetFromObjectsArrayPipe,
    HasPermissionPipe,
    HourConvertPipe,
    LanguageChangePipe,
    LocalizedDatePipe,
    ReplacePipe,
    YesOrNoPipe,
    // VER ESTE 
    LogoComponent,
    TagComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    //VER ESTE
    NgxMaskModule.forRoot(options),
    NzTagModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-Pt' },
    { provide: NZ_I18N, useValue: pt_PT },
    { provide: OPTIONS_TOKEN, useValue: WP_CONFIGURATION },
  ],
  exports: [
    DataUnitsPipe,
    FileUploadModule,
    EuroCurrenyPipe,
    FormsModule,
    GenderBeautifyPipe,
    GetFromObjectsArrayPipe,
    HasPermissionPipe,
    HourConvertPipe,
    HttpClientModule,
    LanguageChangePipe,
    LocalizedDatePipe,
    LogoComponent,
    TagComponent,
    NgxMaskModule,
    ReactiveFormsModule,
    ReplacePipe,
    TranslateModule,
    YesOrNoPipe,
  ],
  entryComponents: [],
})
export class SharedModule { }
