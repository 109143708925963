import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { TranslateModule } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ButtonModule } from '../button/button.module';



@NgModule({
  declarations: [
    FileUploadComponent,
  ],
  imports: [
    CommonModule,
    NzButtonModule,
    NzUploadModule,
    NzIconModule.forChild([]),
    TranslateModule.forChild(),
    ButtonModule
  ],
  exports: [
    FileUploadComponent,
  ]
})
export class FileUploadModule { }
