import { SiderItem } from "../../models/sider-item";

export const SideBarItems: SiderItem[] = [
  {
    code: 'COMPANY_PROFILE',
    name: 'JOB_PORTAL.COMPANY_PROFILE',
    link: '/personal-data',
    icon: 'user',
    serviceId: 1,
    children: [],
    scope: "sasocial:is_company"
  },
  {
    code: 'JOB_PORTAL',
    name: 'DASHBOARD.BUTTON.JOB_PORTAL',
    link: '/job-portal',
    icon: 'icons:icons-job-portal',
    serviceId: 1,
    children: [
      { code: 'DASHBOARDS', name: 'JOB_PORTAL.ANALYSIS_DASHBOARD', link: '/job-portal/dashboard', disable: false, scope: 'job_portal:offers:read' },
      { code: 'JOB_OFFERS', name: 'JOB_PORTAL.MANAGING_JOB_OFFERS', link: '/job-portal/job-offers', disable: false, scope: 'job_portal:offers:read' },
      { code: 'INTERNSHIP_OFFERS', name: 'JOB_PORTAL.MANAGING_INTERNSHIP_OFFERS', link: '/job-portal/internship-offers', disable: false, scope: 'job_portal:offers:read' },
      { code: 'EMPLOYMENT_EVENTS', name: 'JOB_PORTAL.EMPLOYMENT_EVENTS.DASHBOARD.EMPLOYMENT_EVENT', link: '/job-portal/employment-events', disable: false, scope: 'job_portal:employment_events:read' },
      { code: 'CANDIDATES', name: 'JOB_PORTAL.EMPLOYMENT_EVENTS.DASHBOARD.CANDIDATES', link: '/job-portal/candidates', disable: false, scope: 'job_portal:candidates:read' },
    ],
    scope: "job_portal:offers:read"
  },
  {
    code: 'INCLUSIVE_SCHOOL',
    name: 'DASHBOARD.BUTTON.INCLUSIVE_SCHOOL',
    link: '/inclusive_school',
    icon: 'icons:icons-inclusive_school',
    serviceId: 1,
    children: [
      { code: 'PROJECTS', name: 'INCLUSIVE_SCHOOL.TABS.PROJECTS', link: '/inclusive_school/projects', disable: false },
    ]
  },
  {
    code: 'ENQUIRIES',
    name: 'DASHBOARD.BUTTON.ENQUIRIES',
    link: '/surveys',
    icon: 'form',
    serviceId: 1,
    children: [],
    scope: "sitech:user"
  },
];
