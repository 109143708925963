import { Component, forwardRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { EMPTY, Observable, Observer } from 'rxjs';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { FiConfigurator } from '@fi-sas/configurator';
import { FileMessageModel } from '@fi-sas/webpage/shared/models/file-message.model';
import { FilesService } from '@fi-sas/webpage/modules/media/services/files.service';
import { MessageType, UiService } from '@fi-sas/webpage/core/services/ui.service';
import { FileModel } from '@fi-sas/webpage/modules/media/models/file.model';
import { NzUploadFile, NzUploadListType, NzUploadXHRArgs, UploadFilter } from 'ng-zorro-antd/upload';

interface UploadedFile {
  id: number;
  uid: string;
  name: string;
  status: string;
  url: string;
  thumbUrl: string;
  file: FileModel;
  originalName?: string;
}

function getBase64(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {
  public _value;
  categoryId: number;
  languageId: number;
  disableUploadButton = false;
  filesUploaded: NzUploadFile[] = [];
  loadingFiles = false;

  previewImage: string | undefined = '';
  previewVisible = false;

  filesIDS: {
    file_id: number;
    uid: string;
  }[] = [];

  @Input() isPublic = 'true';
  @Input() weight = 1;
  @Input() multiUploads = false;
  @Input() listType: NzUploadListType = 'text';
  @Input() buttonName = 'FILE.BUTTON';
  @Input() filesTypes: string | null = null;
  /* Files Types:
   *   image/png
   *   image/jpeg
   *   image/bmp
   *   application/pdf
   *   application/doc
   *   image/*
   *   audio/*
   *   video/*
   *   */

  @Input() limit = 0;
  @Input() filterTypes = [];
  @Input() styleType = "";
  @Input() label = null
  @Input() icon = null
  @Input() messages: FileMessageModel = {
    upload: {
      sucess: 'FILE.UPLOAD.SUCCESS',
      error: 'FILE.UPLOAD.ERROR',
    },
    delete: {
      sucess: 'FILE.DELETE.SUCCESS',
      error: 'FILE.DELETE.ERROR',
    },
    load: {
      error: 'FILE.LOAD.ERROR',
    },
  };
  private _filesList = []
  @Input() set filesList(filesList) {
    this._filesList = filesList;
    this.loadingFiles = true;
    if (this.filesList.length !== 0) {
      this.loadFiles();
    } else {
      this.loadingFiles = false;
    }
  }
  get filesList() {
    return this._filesList
  }

  filters: UploadFilter[] = [
    {
      name: 'type',
      fn: (fileList: NzUploadFile[]) => {
        if (this.filterTypes.length !== 0) {
          const filterFiles = fileList.filter((w) => ~this.filterTypes.indexOf(w.type));
          if (filterFiles.length !== fileList.length) {
            this.uiService.showMessage(MessageType.error, this.translateService.instant('FILE.TYPE.INVALID'));
            return filterFiles;
          }
        }
        return fileList;
      },
    },
    {
      name: 'async',
      fn: (fileList: NzUploadFile[]) => {
        return new Observable((observer: Observer<NzUploadFile[]>) => {
          // doing
          observer.next(fileList);
          observer.complete();
        });
      },
    },
  ];

  @Output() fileAdded = new EventEmitter();
  @Output() fileDeleted = new EventEmitter();
  @Output() filesLoaded = new EventEmitter();

  onChange: any = () => { };
  onTouched: any = () => { };
  typeFile: NzUploadFile = null;

  constructor(
    private translateService: TranslateService,
    private uiService: UiService,
    private filesServices: FilesService,
    private configurator: FiConfigurator
  ) {
    if (!this.languageId) {
      this.languageId = this.configurator.getOption('DEFAULT_LANG_ID');
    }

    if (!this.categoryId) {
      this.categoryId = this.configurator.getOption('DEFAULT_CATEGORY_ID');
    }
  }

  ngOnInit() { }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(this._value);
    this.onTouched();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (value) this.loadFile(value);

    if (value !== undefined) {
      this.value = value;
      this.onChange(this.value);
    }
  }

  uploadFile = (item: NzUploadXHRArgs) => {
    const formData = new FormData();
    formData.append('name', item.file.name);
    formData.append('weight', this.weight.toString());
    formData.append('public', this.isPublic);
    formData.append('file_category_id', this.categoryId.toString());
    formData.append('language_id', this.languageId.toString());
    formData.append('file', item.file as any, item.file.name);
    return this.filesServices.createFile(formData).pipe(first()).subscribe(
      (file) => {
        this.value = file.data[0].id;
        item.onSuccess!({}, item.file!, event);
        this.filesUploaded.push({
          id: file.data[0].id,
          uid: item.file.uid,
          name: file.data[0].filename,
          status: 'done',
          url: file.data[0].url,
          thumbUrl: file.data[0].url,
          file: file.data[0],
          originalName: item.file.name,
        });
        this.multiUploads ? (this.disableUploadButton = false) : (this.disableUploadButton = true);
        this.filesIDS.push({ file_id: file.data[0].id, uid: item.file.uid });
        if (this.limit !== 0) {
          this.disableUploadButton = this.limit === this.filesIDS.length;
        }
        this.uiService.showMessage(MessageType.success, this.translateService.instant(this.messages.upload.sucess));
        this.fileAdded.emit(this.value);
      },
      (err) => {
        item.onError!('error', item.file!);
        this.uiService.showMessage(MessageType.error, this.translateService.instant(this.messages.upload.error));
      }
    );
  };

  removeFile = (file: NzUploadFile) => {
    if (this.filesIDS.length !== 0) {
      const findedFile = this.filesIDS.find((fileID) => fileID.uid === file.uid);
      if (findedFile) {
        return this.remove(findedFile.file_id);
      }
    }
    return true;
  };

  remove(fileID: number): Observable<boolean> {
    return Observable.create(
      (o) => {
        this.filesServices
          .removeFile(fileID)
          .pipe(first())
          .subscribe(
            () => {
              // this.fileDeleted.emit(this.value);

              this.filesIDS.splice(
                this.filesIDS.findIndex((id) => id.file_id === fileID),
                1
              );

              this.filesIDS = [...this.filesIDS];

              this.fileDeleted.emit(fileID);
              this.uiService.showMessage(
                MessageType.success,
                this.translateService.instant(this.messages.delete.sucess)
              );
              this.disableUploadButton = false;
              o.next(true);
              o.complete();
            },
            () => {
              this.uiService.showMessage(MessageType.error, this.translateService.instant(this.messages.delete.error));
              o.error(false);
              o.complete();
            }
          );
      },
      () => {
        return EMPTY;
      }
    );
  }

  handlePreview = (file: NzUploadFile) => {
    this.typeFile = file ? file : null;
    if (!file.url && !file['preview']) {
      file['preview'] = getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file['preview'];
    this.previewVisible = true;
    if (this.typeFile.type != '') {
      if (this.filesUploaded.find((fileID) => fileID.uid === this.typeFile.uid)) {
        window.open(this.getURL());
      }

    } else if (this.typeFile.url != '') {
      window.open(this.typeFile.url);
    }
  };

  getURL() {
    return this.filesUploaded.find((fileID) => fileID.uid === this.typeFile.uid).url;
  }

  loadFile(id: number) {
    this.loadingFiles = true;
    this.filesServices
      .file(id)
      .pipe(first())
      .subscribe(
        (result) => {
          this.filesUploaded.push({
            id: result.data[0].id,
            uid: result.data[0].id.toString(),
            name: result.data[0].filename,
            status: 'done',
            url: result.data[0].url,
            thumbUrl: result.data[0].url,
            file: result.data[0],
          });
          this.filesIDS.push({
            file_id: result.data[0].id,
            uid: result.data[0].id.toString(),
          });

          this.multiUploads ? (this.disableUploadButton = false) : (this.disableUploadButton = true);
          if (this.limit !== 0) {
            this.disableUploadButton = this.limit === this.filesIDS.length;
          }

          this.loadingFiles = false;
          this.filesLoaded.emit(this.filesIDS);
        },
        () => {
          this.uiService.showMessage(MessageType.error, this.translateService.instant(this.messages.load.error));
          this.loadingFiles = false;
          this.value = null;
        }
      );
  }

  loadFiles(): void {
    this.filesServices
      .listfiles(this.filesList)
      .pipe(first())
      .subscribe(
        (files) => {
          files.data.forEach((file) => {
            this.filesUploaded.push({
              id: file.id,
              uid: file.id.toString(),
              name: file.filename,
              status: 'done',
              url: file.url,
              thumbUrl: file.url,
              file,
            });
            this.filesIDS.push({ file_id: file.id, uid: file.id.toString() });
            if (this.limit !== 0) {
              this.disableUploadButton = this.limit === this.filesIDS.length;
            }
          });
          this.loadingFiles = false;
        },
        () => {
          this.uiService.showMessage(MessageType.error, this.translateService.instant(this.messages.load.error));
          this.loadingFiles = false;
        }
      );
  }
}
