import { SharedModule } from './../shared/shared.module';
import { AuthTemplateComponent } from './templates/auth-template/auth-template.component';
import { UiService } from './services/ui.service';
import { NgModule, Optional, SkipSelf, APP_BOOTSTRAP_LISTENER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/core';
import { FullTemplateComponent } from './templates/full-template/full-template.component';
import { LayoutHeaderComponent } from './components/layout-header/layout-header.component';
import { LayoutSlideMenuComponent } from './components/layout-slide-menu/layout-slide-menu.component';
import { LayoutBreadcrumbComponent } from './components/layout-breadcrumb/layout-breadcrumb.component';
import { RouterModule } from '@angular/router';
import { LayoutHeaderUserComponent } from './components/layout-header-user/layout-header-user.component';
import { LayoutFooterComponent } from './components/layout-footer/layout-footer.component';
import { SidebarOptionsChangeService } from '@fi-sas/webpage/core/services/sidebar-options-change.service';
import { VersionCheckService } from './services/version-check.service';
import { LayoutMobileMenuComponent } from './components/layout-mobile-menu/layout-mobile-menu.component';
import { LayoutReportsComponent } from './components/reports/reports.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { LayoutNotificationsComponent } from './components/notifications/notifications.component';
import { LayoutCurrentAccountComponent } from './components/current-account/current-account.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzMessageModule } from 'ng-zorro-antd/message';

@NgModule({
  declarations: [
    FullTemplateComponent,
    LayoutHeaderComponent,
    LayoutSlideMenuComponent,
    LayoutBreadcrumbComponent,
    AuthTemplateComponent,
    LayoutHeaderUserComponent,
    LayoutReportsComponent,
    LayoutFooterComponent,
    LayoutMobileMenuComponent,
    ShoppingCartComponent,
    LayoutNotificationsComponent,
    LayoutCurrentAccountComponent
  ],
  imports: [
    CommonModule,
    RouterModule,

    NzDropDownModule,
    NzGridModule,
    NzLayoutModule,
    NzCardModule,
    NzDropDownModule,
    NzAvatarModule,
    NzButtonModule,
    NzListModule,
    NzBreadCrumbModule,
    NzDrawerModule,
    NzBadgeModule,
    NzSpinModule,
    NzToolTipModule,
    NzDividerModule,
    NzIconModule,
    NzPopoverModule,
    NzMessageModule,
    SharedModule,
  ],
  providers: [
    UiService,
    SidebarOptionsChangeService,
    VersionCheckService,
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('Core Module is already loaded use only on Root Module');
    }
  }
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [UiService]
    };
  }
}
