import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppResolver } from "./app.resolver";
import { PrivateGuard } from "./auth/guards/private.guard";
import { PublicGuard } from "./auth/guards/public.guard";
import { FullTemplateComponent } from "./core/templates/full-template/full-template.component";

const routes: Routes = [
  {
    path: "auth",
    component: FullTemplateComponent,
    resolve: [AppResolver],
    children: [
      {
        path: "login",
        loadChildren: () =>
          import("src/app/pages/login/login.module").then((m) => m.LoginModule),
        data: { breadcrumb: "LOGIN.AUTHENTICATION" },
      },
      {
        path: "reset-password",
        loadChildren: () =>
          import("src/app/pages/reset-password/reset-password.module").then(
            (m) => m.ResetPasswordModule
          ),
        data: { breadcrumb: "RESET_PASSWORD.TITLE" },
      },
      {
        path: "new-password",
        loadChildren: () =>
          import("src/app/pages/new-password/new-password.module").then(
            (m) => m.NewPasswordModule
          ),
        data: { breadcrumb: "CHANGE_PASSWORD.NEW_PASSWORD" },
      },
      {
        path: "new-verification-code",
        loadChildren: () =>
          import(
            "src/app/pages/new-verification-code/new-verification-code.module"
          ).then((m) => m.NewVerificationCodeModule),
        data: { breadcrumb: "NEW_VERIFICATION.TITLE" },
      },
      {
        path: "register",
        loadChildren: () =>
          import(
            "src/app/pages/register/register.module"
          ).then((m) => m.RegisterModule),
        data: { breadcrumb: "REGISTER.TITLE" },
      },
      {
        path: "ssoError",
        loadChildren: () =>
          import("src/app/pages/sso-error/sso-error.module").then(
            (m) => m.SsoErrorModule
          ),
        data: { breadcrumb: "SSO_ERROR.BREADCRUMB" },
      },
      { path: "", redirectTo: "login", pathMatch: "full" },
    ],
    canActivate: [PublicGuard],
  },
  {
    path: "",
    component: FullTemplateComponent,
    resolve: [AppResolver],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("src/app/pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        canActivate: [PrivateGuard],
        data: { breadcrumb: "DASHBOARD.BUTTON.SERVICES" },
      },
      {
        path: "news",
        canActivate: [PrivateGuard],
        loadChildren: () =>
          import("./modules/news/news.module").then((m) => m.NewsModule),
        data: { breadcrumb: "DASHBOARD.WIDGETS.POSTS.TITLE" },
      },
      {
        path: "account-verification",
        loadChildren: () =>
          import(
            "src/app/pages/account-verification/account-verification.module"
          ).then((m) => m.AccountVerificationModule),
        data: { breadcrumb: "ACCOUNT_VERIFICATION.TITLE" },
      },
      {
        path: "unauthorized",
        loadChildren: () =>
          import("src/app/pages/unauthorized/unauthorized.module").then(
            (m) => m.UnauthorizedModule
          ),
        data: { breadcrumb: "UNAUTHORIZED.UNAUTHORIZED" },
      },
      {
        path: "regulations",
        loadChildren: () => import(
          "./pages/regulations/regulations.module").then(m => m.RegulationsModule),
      },
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
    ],
    canActivateChild: [PublicGuard],
    data: { breadcrumb: null },
  },
  {
    path: "",
    component: FullTemplateComponent,
    resolve: [AppResolver],
    children: [
      {
        path: "profile",
        loadChildren: () =>
          import("src/app/pages/profile/profile.module").then(
            (m) => m.ProfileModule
          ),
        data: { breadcrumb: "CORE.PROFILE" },
        canActivate: [PrivateGuard],
      },
      {
        path: "personal-data",
        loadChildren: () =>
          import("src/app/pages/personal-data/personal-data.module").then(
            (m) => m.PersonalDataModule
          ),
        data: { breadcrumb: "PROFILE_PAGE.PERSONAL_DATA" },
        canActivate: [PrivateGuard],
      },
      {
        path: "firstLogin",
        loadChildren: () =>
          import("src/app/pages/first-login/first-login.module").then(
            (m) => m.FirstLoginModule
          ),
        data: { breadcrumb: "LOGIN.FIRST_LOGIN.TITLE" },
        canActivate: [PrivateGuard],
      },
      {
        path: "change-pin",
        loadChildren: () =>
          import("src/app/pages/change-pin/change-pin.module").then(
            (m) => m.ChangePinModule
          ),
        data: { breadcrumb: "PROFILE_PAGE.CHANGE_PIN" },
        canActivate: [PrivateGuard],
      },
      {
        path: "change-password",
        loadChildren: () =>
          import("src/app/pages/change-password/change-password.module").then(
            (m) => m.ChangePasswordModule
          ),
        data: { breadcrumb: "PROFILE_PAGE.CHANGE_PASSWORD" },
        canActivate: [PrivateGuard],
      },
      {
        path: "disable-account",
        loadChildren: () =>
          import("src/app/pages/disable-account/disable-account.module").then(
            (m) => m.DisableAccountModule
          ),
        data: { breadcrumb: "PROFILE_PAGE.DISABLE_ACCOUNT" },
        canActivate: [PrivateGuard],
      },
      {
        path: "qrcode",
        loadChildren: () =>
          import("src/app/pages/qrcode-ticket/qrcode-ticket.module").then(
            (m) => m.QrcodeTicketModule
          ),
        data: { breadcrumb: "QRCODE.TITLE" },
        canActivate: [PrivateGuard],
      },
      {
        path: "not-found",
        loadChildren: () =>
          import("src/app/pages/not-found-page/not-found-page.module").then(
            (m) => m.NotFoundPageModule
          ),
        data: { breadcrumb: "MISC.PAGE_NOT_FOUND" },
      },
      {
        path: "calendar",
        loadChildren: () =>
          import("./modules/calendar/calendar.module").then(
            (m) => m.CalendarModule
          ),
        data: {
          breadcrumb: "DASHBOARD.BUTTON.CALENDAR",
          scope: "calendar",
        },
        canActivate: [PrivateGuard],
      },
      {
        path: "job-portal",
        loadChildren: () =>
          import("./modules/job-portal/job-portal.module").then(
            (m) => m.JobPortalModule
          ),
        data: {
          breadcrumb: "DASHBOARD.BUTTON.JOB_PORTAL",
          scope: "job_portal",
        },
        canActivate: [PrivateGuard],
      },
      {
        path: "inclusive_school",
        loadChildren: () =>
          import("./modules/inclusive-school/inclusive-school.module").then(
            (m) => m.InclusiveSchoolModule
          ),
        data: {
          breadcrumb: "DASHBOARD.BUTTON.INCLUSIVE_SCHOOL",
        },
        canActivate: [PrivateGuard],
      },
      {
        path: "surveys",
        loadChildren: () =>
          import("./modules/surveys/survey.module").then(
            (m) => m.SurveyModule
          ),
        data: {
          breadcrumb: "DASHBOARD.BUTTON.ENQUIRIES",
          scope: "calendar",
        },
        canActivate: [PrivateGuard],
      },
    ],
    canActivateChild: [PrivateGuard],
    data: { breadcrumb: null },
  },
  {
    path: "",
    component: FullTemplateComponent,
    resolve: [AppResolver],
    children: [
      {
        path: "**",
        loadChildren: () =>
          import("src/app/pages/not-found-page/not-found-page.module").then(
            (m) => m.NotFoundPageModule
          ),
        data: { breadcrumb: "MISC.PAGE_NOT_FOUND" },
      },
    ],
    data: { breadcrumb: null },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: "disabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
