import { Component, OnInit, Input } from '@angular/core';

export interface TagResult {
    color: string;
    label: string;
}

@Component({
    selector: 'fi-sas-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.less']
})
export class TagComponent implements OnInit {
    public static readonly YesNoTag = { true: { label: 'Sim', color: 'green'}, false: { label: 'Não', color: 'red'} };
    isNull = false;

    @Input() value = null;

    @Input() results: { [key: string]: TagResult } = { 
                  true: { label: 'Sim', color: 'green'},
                  false: { label: 'Não', color: 'red'}
                };

    constructor() { }

    ngOnInit() {
    }

    selectedColor(): string {
      if(!this.results) {
        throw Error(`The results of tag component can't be null`);
      }

      this.isNull = this.value === null;

      if(this.isNull) { return 'gray' };

        const keyFind = Object.keys(this.results).find(key => key === this.value.toString());
        if (keyFind) {
            return this.results[keyFind].color;
        } else {
            return '';
        }
    }


    selectedLabel(): string {
        this.isNull = this.value === null;

        if(this.isNull) { return 'Sem valor' };

        const keyFind = Object.keys(this.results).find(key => key.toString() === this.value.toString())

        if (keyFind) {
            return this.results[keyFind].label;
        } else {
            return '';
        }
    }

}
